<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong class="mr-3">Laporan Mutasi</strong>
          <label>Antara Tanggal: </label>
          <a-range-picker class="ml-2" style="width: 250px;" :default-value="[moment(), moment()]" format="DD-MM-YYYY" :allowClear="true" @change="changePeriode" />
          <a-button class="ml-2 btn btn-outline-primary" @click="generateLaporanHarian">Laporan Harian PDF</a-button>
          <!-- <a-date-picker
            class="ml-2"
            style="width: 100px;"
            v-model="tgl1"
            :allowClear="false"
            format="DD-MM-YYYY"
            @change="(dateMoment, dateString) => changePeriode(dateMoment, dateString, 'tgl1')"
          />
          <label class="ml-3">s.d: </label>
          <a-date-picker
            class="ml-2 mr-2"
            style="width: 100px;"
            v-model="tgl2"
            :allowClear="false"
            format="DD-MM-YYYY"
            @change="(dateMoment, dateString) => changePeriode(dateMoment, dateString, 'tgl2')"
          /> -->
          <!-- <label class="ml-4">Cabang: </label>
          <a-select class="ml-2 mr-2" style="width: 80px;" v-model="cabang">
            <a-select-option value="00">00</a-select-option>
            <a-select-option value="01">01</a-select-option>
            <a-select-option value="02">02</a-select-option>
          </a-select> -->
          <!-- <a-button class="btn btn-outline-danger btn-sm" @click="resetFilter">Reset Filter</a-button> -->
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <a-table
              id="printinikepdf"
              :columns="columns"
              :dataSource="data"
              :key="componentKey"
              size="small"
              :scroll="{ y: 500 }"
              :pagination="{
                defaultPageSize: 1000000,
                hideOnSinglePage: true,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['10', '20', '30'],
              }"
            >
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px;"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`Search ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block;"
                  @change="
                    (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px;"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                >
                  Search
                </a-button>
                <a-button
                  size="small"
                  style="width: 90px;"
                  @click="() => handleReset(clearFilters)"
                >
                  Reset
                </a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>
                  <span v-if="column.dataIndex === 'name'">
                    {{ text }}
                  </span>
                  <span v-else>
                    {{ text }}
                  </span>
                </template>
              </template>
              <template slot="cetak" slot-scope="text, record">
                <a-button class="btn btn-outline-success" @click="reGenerateValidasi(record)">
                  <a-icon type="printer" />
                </a-button>
              </template>
              <strong slot="no" slot-scope="text, record, index">
                {{ index + 1 }}
              </strong>
              <label slot="formatcurency" slot-scope="text">
                {{ formatCurrency(text) }}
              </label>
              <label slot="tgl" slot-scope="text">
                {{ formatTanggal(text) }}
              </label>
            <template slot="mutasi" slot-scope="text, record">
              {{ record.debet === 0 ? formatCurrency(record.kredit) : formatCurrency(record.debet) }}
            </template>
            <template slot="saldo" slot-scope="text, record">
              {{ formatCurrency(record.saldo) }}
            </template>
            </a-table>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import JSPDF from 'jspdf'
import 'jspdf-autotable'

export default {
  data() {
    return {
      tgl1: moment().format('YYYY-MM-DD'),
      tgl2: moment().format('YYYY-MM-DD'),
      cabang: '',
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      columns: [
        {
          title: 'Cetak',
          width: 50,
          scopedSlots: { customRender: 'cetak' },
        },
        {
          title: 'No',
          key: 'no',
          width: 50,
          scopedSlots: { customRender: 'no' },
        },
        {
          title: 'Tanggal',
          dataIndex: 'tgl',
          width: 100,
          scopedSlots: { customRender: 'tgl' },
        },
        {
          title: 'Nomor Transaksi',
          dataIndex: 'faktur',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.faktur.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Nomor Rekening',
          dataIndex: 'rekening',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.rekening.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Keterangan',
          dataIndex: 'keterangan',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender',
          },
          onFilter: (value, record) =>
            record.name.toString().toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              }, 0)
            }
          },
        },
        {
          title: 'Debet',
          dataIndex: 'debet',
          scopedSlots: { customRender: 'formatcurency' },
          align: 'right',
        },
        {
          title: 'Kredit',
          dataIndex: 'kredit',
          scopedSlots: { customRender: 'formatcurency' },
          align: 'right',
        },
        // {
        //   title: 'Akses',
        //   dataIndex: 'privileges',
        //   scopedSlots: { customRender: 'privileges' },
        // },
      ],
      data: [],
      backupData: [],
      componentKey: 0,
    }
  },
  created () {
    this.getAllData()
  },
  methods: {
    moment,
    generateLaporanHarian() {
      const doc = new JSPDF()
      var header = function(data) {
        // console.log('data', data)
        doc.setFontSize(7)
        // doc.setFontType('bold')
        doc.text('Page : ' + data.pageNumber, 186, 10)
        var systime = moment().format('DD-MM-YYYY hh:mm:ss')
        doc.text(systime + ' SYSTEM', 162, 15)

        doc.setFontSize(10).setFont(undefined, 'bold')
        var text = 'Daftar Mutasi Tabungan Harian'
        // var xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        doc.text(text, 80, 23)
        var text1 = 'Tabungan Tanaman'
        // var xOffset1 = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        doc.text(text1, 89, 29)
        var text2 = 'Tanggal : ' + moment().lang('id').format('DD MMMM YYYY')
        // var xOffset2 = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(text) * doc.internal.getFontSize() / 2)
        doc.text(text2, 84, 35)
        // doc.setFontType('normal')
        // doc.text(header2, 20, 52)

        // doc.setDrawColor(255, 255, 255, 255)
        // doc.setLineWidth(2)
        // doc.line(20, 56, 555, 56)
        // Footer
        // var str = 'Page ' + doc.internal.getNumberOfPages()

        // doc.setFontSize(10)

        // // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        // console.log('doc.internal.getNumberOfPages()', doc.internal.getNumberOfPages())
        // var pageSize = doc.internal.pageSize
        // var pageHeight = pageSize.height
        //   ? pageSize.height
        //   : pageSize.getHeight()
        // doc.text(str, data.settings.margin.left, pageHeight - 10)
      }
      var ncolumn = []
      var ndatatable = []
      var exclude = ['Cetak', 'No']
      var include = ['Tanggal', 'Nomor Transaksi', 'Nomor Rekening']
      this.columns.forEach(element => {
        if (!exclude.includes(element.title)) {
          var nobj = {
            dataKey: element.dataIndex,
            columnWidths: 100,
            title: element.title,
          }
          if (include.includes(element.title)) {
            nobj.cell = {
              styles: { halign: 'center' },
            }
          }
          ncolumn.push(nobj)
        }
      })
      var dataclonded = this.$g.clone(this.data)
      var kreditsubtotal = 0
      var debetsubtotal = 0
      // var kreditgrandtotal = 0
      // var debetgrandtotal = 0
      dataclonded.forEach(element => {
        // console.log('element.kredit', element.kredit)
        // console.log('element.debet', element.debet)
        var ntgl = element.tgl.substring(0, 10)
        // console.log('ntgl', ntgl)
        element.tgl = moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
        element.therealkredit = element.kredit !== null && element.kredit !== '' ? element.kredit : ''
        element.therealdebet = element.debet !== null && element.debet !== '' ? element.debet : ''
        element.kredit = element.kredit !== null && element.kredit !== '' ? this.formatCurrency(element.kredit) : ''
        element.debet = element.debet !== null && element.debet !== '' ? this.formatCurrency(element.debet) : ''
        ndatatable.push(element)
      })
      ndatatable.forEach(element => {
        debetsubtotal += parseInt(element.therealdebet)
        kreditsubtotal += parseInt(element.therealkredit)
        // debetgrandtotal += element.debet
        // kreditgrandtotal += element.kredit
      })
      var objtotal = { content: 'Data', keterangan: 'Sub Total', debet: this.formatCurrency(debetsubtotal), kredit: this.formatCurrency(kreditsubtotal), colSpan: 4, rowSpan: 1, bodyStyles: { halign: 'center', fontStyle: 'bold' } }
      // var objtotal1 = { content: 'Data', keterangan: 'Grand Total', debet: debetgrandtotal, kredit: kreditgrandtotal, colSpan: 4, rowSpan: 1, bodyStyles: { halign: 'center', fontStyle: 'bold' } }
      ndatatable.push(objtotal)
      // console.log('objtotal', objtotal)
      // ndatatable.push(objtotal1)
      // console.log('ndatatable', ndatatable)
      var height = 0
      doc.autoTable({
        body: ndatatable,
        columns: ncolumn,
        columnStyles: {
          0: { halign: 'center' },
          1: { halign: 'center' },
          2: { halign: 'center' },
          4: { halign: 'right' },
          5: { halign: 'right' },
        },
        theme: 'grid',
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          fontSize: 7,
          lineWidth: 0.1,
          lineColor: [0, 0, 0],
        },
        margin: { top: 39 },
        showFoot: 'lastPage',
        beforePageContent: header,
        // didDrawPage: function (data) {
        //   height = data.table.height
        // },
        didParseCell: (data) => {
          var rows = data.table.body
          if (data.row.index === rows.length - 1) {
            data.cell.styles.fontStyle = 'bold'
            data.cell.styles.halign = 'right'
          }
          // console.log('data.row', data.row)
          // console.log('data.column', data.column)
          // console.log('data.section', data.section)
        },
        didDrawCell: (data) => {
          // console.log('data.cell', data.cell)
          // console.log('data.row', data.row)
          // console.log('data.column', data.column)
          // console.log('data.section', data.section)
        },
        bodyStyles: {
          cellPadding: 0.6,
          valign: 'middle',
        },
      })
      height = doc.lastAutoTable.finalY
      console.log('height', height)
      doc.setFontSize(8).setFont(undefined, 'bold')
      doc.text('Malang, ' + moment().format('DD-MM-YYYY'), 170, height + 5)
      doc.text('Pembuat', 40, height + 15)
      doc.text('Diperiksa', 100, height + 15)
      doc.text('Menyetujui', 160, height + 15)
      var pname = 'Nama Orang 1'
      doc.text(pname, 38, height + 45).setFont(undefined, 'bold')
      var pname1 = 'Nama Orang 2'
      doc.text(pname1, 98, height + 45).setFont(undefined, 'bold')
      var pname2 = 'Nama Orang 3'
      doc.text(pname2, 158, height + 45).setFont(undefined, 'bold')
      doc.text('CS', 45, height + 50)
      doc.text('Kaops', 103, height + 50)
      doc.text('Direksi', 163, height + 50)
      doc.output('dataurlnewwindow')
    },
    async reGenerateValidasi(record) {
      var res = await lou.customUrlGet2('informasirekening/validasi/' + record.id)
      // doc.text(string/array[string], marginleft, margintop)
      if (res) {
        var leftMargin = 20
        var topMargin = 50
        var splitSize = 160 + leftMargin
        var data = res.data
        var time = data.time.toString().replace('T', ' ')
        var time1 = time.substring(0, 18)
        const doc = new JSPDF()
        doc.setFontSize(10)
        var theString = data.faktur + '  ' + moment(data.tgl, 'YYYY-MM-DD').format('DD-MM-YY') + '  ' + moment(time1, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YY HH:mm:ss') + '  ' + data.rekening + '  ' + data.username + '  ' + data.kodetransaksi + '  ' + data.namatransaksi + '  ' + data.dk + ' Rp  ' + lou.curency(data.jumlah) + ' #'
        var splitText = doc.splitTextToSize(theString, splitSize)
        doc.text(splitText, leftMargin, topMargin)
        // var filename = 'Validasi'
        doc.output('dataurlnewwindow')
      }
    },
    async getAllData() {
      var res = await lou.customUrlGet2('informasirekening')
      if (res) {
        this.data = res.data
        this.backupData = res.data
        this.filterDataByDate()
      }
    },
    changePeriode(dateMoment, dateString) {
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('from', from)
      if (dateMoment.length === 0) {
        this.data = this.$g.clone(this.backupData)
      } else {
        this.tgl1 = dateMoment[0].format('YYYY-MM-DD')
        this.tgl2 = dateMoment[1].format('YYYY-MM-DD')
        if (this.tgl1 !== '' && this.tgl2 !== '') {
          this.filterDataByDate()
        }
      }
      // if (dateMoment !== null) {
      //   this[from] = dateMoment.format('YYYY-MM-DD')
      //   this.filterDataByDate()
      // }
      // if (this.tgl1 === null && this.tgl2 === null) {
      //   this.data = this.$g.clone(this.backupData)
      // }
    },
    filterDataByDate() {
      var ndata = this.backupData.filter((item) => {
        var ndate = item.tgl !== null ? item.tgl.substring(0, 10) : null
        if (ndate !== null) {
          return ndate >= this.tgl1 &&
          ndate <= this.tgl2
        }
      })
      this.data = this.$g.clone(ndata)
      this.componentKey++
      // console.log('ndata', ndata)
    },
    resetFilter() {
      this.tgl1 = null
      this.tgl2 = null
      this.changePeriode(null)
      // this.getAllData()
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    hitungSaldoAkhir(record, index) {
      // console.log('index', index)
      var total = 0
      for (let i = 0; i <= index; i++) {
        const element = this.data[i]
        // console.log('element', element)
        // console.log('element', element.debet)
        if (element.kredit === 0) {
          total += element.debet
        } else {
          total += element.kredit
        }
      }
      // console.log('total', total)
      return total
    },
    formatTanggal(v) {
      var ntgl = v.substring(0, 10)
      // console.log('ntgl', ntgl)
      return moment(ntgl, 'YYYY-MM-DD').format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
