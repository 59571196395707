var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-card',[_c('template',{slot:"title"},[_c('strong',{staticClass:"mr-3"},[_vm._v("Laporan Mutasi")]),_c('label',[_vm._v("Antara Tanggal: ")]),_c('a-range-picker',{staticClass:"ml-2",staticStyle:{"width":"250px"},attrs:{"default-value":[_vm.moment(), _vm.moment()],"format":"DD-MM-YYYY","allowClear":true},on:{"change":_vm.changePeriode}}),_c('a-button',{staticClass:"ml-2 btn btn-outline-primary",on:{"click":_vm.generateLaporanHarian}},[_vm._v("Laporan Harian PDF")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-table',{key:_vm.componentKey,attrs:{"id":"printinikepdf","columns":_vm.columns,"dataSource":_vm.data,"size":"small","scroll":{ y: 500 },"pagination":{
              defaultPageSize: 1000000,
              hideOnSinglePage: true,
              showQuickJumper: true,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
              pageSizeOptions: ['10', '20', '30'],
            }},scopedSlots:_vm._u([{key:"filterDropdown",fn:function({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }){return _c('div',{staticStyle:{"padding":"8px"}},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:((c) => (_vm.searchInput = c)),expression:"(c) => (searchInput = c)"}],staticStyle:{"width":"188px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":`Search ${column.title}`,"value":selectedKeys[0]},on:{"change":(e) => setSelectedKeys(e.target.value ? [e.target.value] : []),"pressEnter":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}}),_c('a-button',{staticStyle:{"width":"90px","margin-right":"8px"},attrs:{"type":"primary","icon":"search","size":"small"},on:{"click":() => _vm.handleSearch(selectedKeys, confirm, column.dataIndex)}},[_vm._v(" Search ")]),_c('a-button',{staticStyle:{"width":"90px"},attrs:{"size":"small"},on:{"click":() => _vm.handleReset(clearFilters)}},[_vm._v(" Reset ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}},{key:"customRender",fn:function(text, record, index, column){return [(_vm.searchText && _vm.searchedColumn === column.dataIndex)?_c('span',[_vm._l((text
                    .toString()
                    .split(
                      new RegExp(`(?<=${_vm.searchText})|(?=${_vm.searchText})`, 'i')
                    )),function(fragment,i){return [(fragment.toLowerCase() === _vm.searchText.toLowerCase())?_c('mark',{key:i,staticClass:"highlight"},[_vm._v(_vm._s(fragment))]):[_vm._v(_vm._s(fragment))]]})],2):[(column.dataIndex === 'name')?_c('span',[_vm._v(" "+_vm._s(text)+" ")]):_c('span',[_vm._v(" "+_vm._s(text)+" ")])]]}},{key:"cetak",fn:function(text, record){return [_c('a-button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.reGenerateValidasi(record)}}},[_c('a-icon',{attrs:{"type":"printer"}})],1)]}},{key:"no",fn:function(text, record, index){return _c('strong',{},[_vm._v(" "+_vm._s(index + 1)+" ")])}},{key:"formatcurency",fn:function(text){return _c('label',{},[_vm._v(" "+_vm._s(_vm.formatCurrency(text))+" ")])}},{key:"tgl",fn:function(text){return _c('label',{},[_vm._v(" "+_vm._s(_vm.formatTanggal(text))+" ")])}},{key:"mutasi",fn:function(text, record){return [_vm._v(" "+_vm._s(record.debet === 0 ? _vm.formatCurrency(record.kredit) : _vm.formatCurrency(record.debet))+" ")]}},{key:"saldo",fn:function(text, record){return [_vm._v(" "+_vm._s(_vm.formatCurrency(record.saldo))+" ")]}}])})],1)])],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }